export interface IHomePageComponentDeal {
	id: number;
	componentID: number;
	blainNumber: string;
	imageUrl: string;
	imageUrlWebp: string;
	largeText: string;
	promoText: string;
	smallText: string;
	description: string;
	linkUrl: string;
	isProduct: boolean;
	sortIndex: number;
	icon: string;
	headline: string;
	format: HomePageComponentDealFormat;
	memberPrice?: number;
}

export enum HomePageComponentDealFormat {
	PRICE = 1,
	DOLLARS_OFF,
	PERCENT_OFF,
	TEXT
}