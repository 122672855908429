import { HomePageComponentDealFormat, IHomePageComponentDeal } from '../types/HomePage';

export function getMainText(deal: IHomePageComponentDeal): string {
	switch (deal.format) {
		case HomePageComponentDealFormat.PRICE:
		case HomePageComponentDealFormat.DOLLARS_OFF:
			return parseDollars(deal.largeText);
		default:
			return deal.largeText || '';
	}
}

export function getMainTextPrefix(deal: IHomePageComponentDeal): string {
	switch (deal.format) {
		case HomePageComponentDealFormat.PRICE:
		case HomePageComponentDealFormat.DOLLARS_OFF:
			return '$';
		default:
			return null;
	}
}

export function getMainTextSuffix(deal: IHomePageComponentDeal): string {
	switch (deal.format) {
		case HomePageComponentDealFormat.PRICE:
			return parseCents(deal.largeText);
		case HomePageComponentDealFormat.DOLLARS_OFF:
			return 'OFF';
		default:
			return null;
	}
}

function parseDollars(largeText: string): string {
	if (!largeText) return '';
	const val = Number(largeText.split('.')[0].replace(/[^0-9]/g, ''));
	if (isNaN(val) || val <= 0) return '';
	return val.toLocaleString();
}

function parseCents(largeText: string): string {
	if (largeText.indexOf('.') === -1) return '00';
	return largeText.split('.')[1].replace(/[^0-9]/g, '').slice(0, 2);
}