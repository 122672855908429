import HotDealsCarousel from './components/HotDealsCarousel.vue';
import Vue from 'vue';

export function initHotDeals(): void {
	const deals = document.querySelectorAll('.hot-deals-wrapper');
	for (let i = 0; i < deals.length; i++) {
		initHotDeal(deals[i].id);
	}
}

function initHotDeal(elementID: string): void {
	const el = document.getElementById(elementID);
	const deals = window[elementID];
	if (!el || !deals) return;
	el.getElementsByTagName('script')[0].remove();
	new Vue({
		el: `#${elementID}`,
		components: { HotDealsCarousel },
		data: { deals }
	});
}