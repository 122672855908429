
import Icon from '../Icon.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'CarouselPageButton',
	components: {
		Icon
	}
})
export default class CarouselPageButton extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Boolean, required: false, default: true })
	readonly hideMobile: boolean;

	@Prop({ type: Boolean, required: false, default: false })
	readonly hideTablet: boolean;

	@Prop({ type: Boolean, required: false })
	readonly right: boolean;

	/* Computed
	============================================*/

	get label(): string {
		return this.right ? 'next' : 'previous';
	}

	get iconName(): string {
		return this.right ? 'chevron-right' : 'chevron-left';
	}

}

