require('lessRoot/homepage.less');
import { createImageCarousel } from 'JS/image-carousel';
import { dateDiff } from './utilities/dates';
import { initHotDeals } from './global/hot-deals/hot-deals';
import { padLeft } from './utilities/string';

function initCountdown(el) {
	const countdown = el.querySelector('.countdown');
	if (!countdown || !countdown.getAttribute('data-date-end')) return;
	const endDate = new Date(countdown.getAttribute('data-date-end'));
	if (endDate.toString() === 'Invalid Date') return;

	const txtDays = el.querySelector("span.days");
	const txtHrs = el.querySelector("span.hours");
	const txtMin = el.querySelector("span.minutes");
	const txtSec = el.querySelector("span.seconds");
	const live = el.querySelector('.countdown-live');
	const ended = el.querySelector('.countdown-ended');
	let timer = null;

	function updateData() {
		const currentTime = new Date();
		let days, hours, minutes, seconds;
		if (endDate > currentTime) {
			days = dateDiff(currentTime, endDate, "d").toString();
			hours = (dateDiff(currentTime, endDate, "h") % 24).toString();
			minutes = (dateDiff(currentTime, endDate, "m") % 60).toString();
			seconds = padLeft((dateDiff(currentTime, endDate, "s") % 60).toString(), 2);
			if (txtDays) txtDays.textContent = days;
			txtHrs.textContent = hours;
			txtMin.textContent = minutes;
			txtSec.textContent = seconds;
		} else {
			clearInterval(timer);
			if (!ended || !ended.textContent) {
				el.remove();
			} else {
				ended.style.display = 'block';
				if (live) live.remove();
			}
		}
	};

	// Run the initial data update
	timer = setInterval(updateData, 1000);
}

(function() {
	initHotDeals();
	// const homeSlider = document.querySelector('.home-carousel');
	// if (homeSlider) createImageCarousel(homeSlider);

	// const countdowns = document.querySelectorAll('.home-countdown');
	// for (let i = 0; i < countdowns.length; i++) {
	// 	initCountdown(countdowns[i]);
	// }
})();