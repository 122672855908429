
import CarouselPageButton from '../../../components/carousels/CarouselPageButton.vue';
import HotDeal from './HotDeal.vue';
import Icon from '../../../components/Icon.vue';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { createListSlider, IListSlider } from 'JS/list-slider';
import { DeviceBreakpoint, ViewportListener } from 'JS/types/Media';
import { IHomePageComponentDeal } from 'src/js/types/HomePage';
import { isMobileWidth, isTabletWidth, onWindowResize } from 'JS/utilities/viewport';

@Component({
	name: 'HotDealsCarousel',
	components: {
		CarouselPageButton,
		HotDeal,
		Icon
	}
})
export default class HotDealsCarousel extends Vue {

	$refs!: {
		btnPrev: Vue;
		btnNext: Vue;
		viewport: HTMLElement;
	}

	/* Props
	============================================*/

	@Prop({ type: Array, required: true })
	readonly deals: IHomePageComponentDeal[];

	@Prop({ type: String, required: false })
	readonly headline: string;

	/* Data
	============================================*/

	slider: IListSlider | null;
	size: number = 10;
	windowWidth: number = window.innerWidth;
	listener: ViewportListener | null;

	/* Methods
	============================================*/

	init() {
		if (this.slider || !this.$refs.viewport) return;

		this.slider = createListSlider(this.$refs.viewport);
		this.slider.setControls(
			this.$refs.btnPrev.$el as HTMLButtonElement,
			this.$refs.btnNext.$el as HTMLButtonElement
		);
	}

	setSize(windowWidth: number) {
		if (windowWidth > 1200) this.size = 10;
		else if (windowWidth > DeviceBreakpoint.Tablet) this.size = 8;
		else if (windowWidth > DeviceBreakpoint.Mobile) this.size = 9;
		else if (windowWidth > 489) this.size = 10;
		else this.size = 7;
	}

	showButtons() {
		if ((this.deals?.length ?? 0) < 2) return false;
		if (!isTabletWidth() && this.deals.length < 4) return false;
		if (!isMobileWidth() && this.deals.length < 3) return false;
		return true;
	}

	/* Lifecycle Hooks
	============================================*/

	created() {
		this.slider = null;
		this.setSize(window.innerWidth);
		this.listener = onWindowResize((width) => this.setSize(width));
	}

	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	}

	beforeDestroy() {
		this.listener?.remove();
	}

	/* Watchers
	============================================*/

	@Watch('size')
	onSizeChange() {
		if (!this.slider) return;
		this.slider.reset();
	}

}

