
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getMainText, getMainTextPrefix, getMainTextSuffix } from 'JS/helpers/hot-deal-helpers';
import { HomePageComponentDealFormat, IHomePageComponentDeal } from 'JS/types/HomePage';

@Component({
	name: 'HotDeal',
})
export default class HotDeal extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Object as () => IHomePageComponentDeal, required: true })
	readonly deal: IHomePageComponentDeal;

	@Prop({ type: Number, required: false, default: 10 })
	readonly size: number;

	/* Computed
	============================================*/

	get fontSize() {
		return (this.size ? this.size : 10) + 'px';
	}

	get headlineColor() {
		if (this.deal.icon === 'truck' || this.deal.icon === 'localdeliver') return '#008040';
		if (this.deal.icon === 'rewards-badge') return '#0054A4';
		if (this.deal.icon === 'deal-fill') return '#7462E0';
		return '#656E97';
	}

	get isPercentOff(): boolean {
		return this.deal.format === HomePageComponentDealFormat.PERCENT_OFF;
	}

	get textClass(): string {
		return this.deal.format === HomePageComponentDealFormat.TEXT
			? 'hd-text custom'
			: 'hd-text';
	}

	get largeTextSize() {
		const chars = (this.text + this.textSuffix).length;
		if (chars > 20) return '2.5em';
		if (chars > 15) return '3.5em';
		if (chars > 6) return '4em';
		if (chars > 2) return '5.5em';
		return '6.5em';
	}

	get hasWasPrice() {
		if (!this.deal) return false;
		return this.deal.isProduct
			&& this.deal.smallText
			&& this.deal.smallText.indexOf('.') > -1;
	}

	get text() {
		return getMainText(this.deal);
	}

	get textPrefix() {
		return getMainTextPrefix(this.deal);
	}

	get textSuffix() {
		return getMainTextSuffix(this.deal);
	}

	/*============================================================
	 == Data
	/============================================================*/

}

